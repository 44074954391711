import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { useEffect, useRef, useState } from 'react'
import EmailPassword from './EmailPassword'
import FacebookButton from './FacebookButton'
import { appleSignIn, facebookSignIn, googleSignIn } from './FirebaseAuth'
import GoogleButton from './GoogleButton'
import AppleButton from './AppleButton'
import {
    EMAIL_TOGGLE,
    EXISTING_LOGIN,
    NEW_LOGIN,
    PHONE_TOGGLE,
} from './LoginConstants'
import { useLoginContext } from './LoginContext'
import PhoneNumber from './PhoneNumber'
import {
    CorporateLogin,
    Disclosure,
    Line,
    PhoneAndEmailButtons,
    Separator,
    SocialButtons,
    StyledH6,
    ToggleButton,
} from './StyledComponents'
import { useCookies } from 'react-cookie'
import { IOS_PWA_APP_TYPE } from '@/src/common/constants'
import LoginFormBaseLayout from '@/src/modules/login/LoginFormBaseLayout'
import {
    isNativeAndroidDevice,
    isNativeIOSDevice,
} from '@/src/common/deviceInfo'
import { FormErrorMessage } from '@/src/common/components/elements/Forms/FormErrorMessage'

type TLoginProps = {
    showCorporateLogin: boolean
}

const LoginForm = ({ showCorporateLogin = false }: TLoginProps) => {
    const {
        actionText,
        loginType,
        setLoginType,
        emailPhoneToggle,
        setEmailPhoneToggle,
        showVerificationScreen,
        setShowVerificationScreen,
        showResetPassword,
        setEmail,
        setPhoneNumber,
    } = useLoginContext()

    const { user, refreshUser, error: authError } = useUser()

    const [isProcessing, setIsProcessing] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const [cookies] = useCookies()
    const appType = cookies?.app_type

    const isNewLogin: boolean = loginType === NEW_LOGIN

    // Clear form values when toggling between sign up/login
    function clearExistingValues() {
        setEmail('')
        setPhoneNumber('')
        setError(null)
    }

    // Clear any existing timers to prevent memory leaks
    const clearTimers = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = null
        }
    }

    // Handle social authentication errors
    const handleSocialAuth = (authFunction: () => Promise<any>) => {
        setIsProcessing(true)
        setError(null)

        authFunction()
            .catch((err) => {
                console.error('Social authentication error:', err)
                setError(
                    err.message || 'Authentication failed. Please try again.'
                )
            })
            .finally(() => {
                setIsProcessing(false)
            })
    }

    // Simplified useEffect since user creation is handled in AuthProvider
    useEffect(() => {
        // Clear any previous errors when auth state changes
        if (authError) {
            setError(authError)
        } else {
            setError(null)
        }

        // Reset processing state if no user
        if (!user) {
            setIsProcessing(false)
        }
    }, [user, authError])

    // Handle email verification flow with improved polling
    useEffect(() => {
        clearTimers() // Clear any existing timers

        if (user?.email && !user.emailVerified) {
            // User has signed up with email but not verified yet
            setEmailPhoneToggle(EMAIL_TOGGLE)
            setShowVerificationScreen(true)

            // Poll for email verification with exponential backoff strategy
            let pollInterval = 3000 // Start with 3 seconds
            const maxPollInterval = 15000 // Max 15 seconds between checks
            const maxPolls = 20 // Maximum number of polling attempts
            let pollCount = 0

            const pollForVerification = () => {
                timerRef.current = setTimeout(async () => {
                    try {
                        await refreshUser()
                        pollCount++

                        // If user is verified, clear timer and update UI
                        if (user?.emailVerified) {
                            clearTimers()
                            setShowVerificationScreen(false)
                            return
                        }

                        // Continue polling with increased interval if under max attempts
                        if (pollCount < maxPolls) {
                            // Increase interval with a cap
                            pollInterval = Math.min(
                                pollInterval * 1.5,
                                maxPollInterval
                            )
                            pollForVerification()
                        } else {
                            console.log(
                                'Reached maximum polling attempts for email verification'
                            )
                        }
                    } catch (err) {
                        console.error('Error during verification check:', err)
                        clearTimers()
                    }
                }, pollInterval)
            }

            // Start polling
            pollForVerification()
        } else if (user?.emailVerified) {
            // Explicitly handle case where user is already verified
            setShowVerificationScreen(false)
        } else if (!user) {
            // Reset verification screen when user logs out
            setShowVerificationScreen(false)
        }

        return clearTimers // Clean up on unmount
    }, [user, refreshUser, setEmailPhoneToggle, setShowVerificationScreen])

    const displayError = error || null

    if (showVerificationScreen || showResetPassword) {
        return (
            <LoginFormBaseLayout>
                {emailPhoneToggle === EMAIL_TOGGLE ? (
                    <EmailPassword />
                ) : (
                    <PhoneNumber />
                )}
                {displayError && (
                    <FormErrorMessage data-testid={'err_login'} role={'alert'}>
                        {displayError}
                    </FormErrorMessage>
                )}
            </LoginFormBaseLayout>
        )
    }

    return (
        <LoginFormBaseLayout>
            {displayError && (
                <FormErrorMessage data-testid={'err_login'} role={'alert'}>
                    {displayError}
                </FormErrorMessage>
            )}

            {/*social login is hidden on legacy iOS PWA apps*/}
            {/*it'll be shown in the React Native Hybrid app*/}
            {appType !== IOS_PWA_APP_TYPE && (
                <>
                    <SocialButtons>
                        {/*capacitor-firebase is fixing a bug with iOS Facebook*/}
                        {/*it will soon be available*/}
                        {!isNativeIOSDevice && (
                            <FacebookButton
                                label={`${actionText} with Facebook`}
                                onClick={() => handleSocialAuth(facebookSignIn)}
                                disabled={isProcessing}
                            />
                        )}
                        <GoogleButton
                            label={`${actionText} with Google`}
                            onClick={() => handleSocialAuth(googleSignIn)}
                            disabled={isProcessing}
                        />
                        {!isNativeAndroidDevice && (
                            <AppleButton
                                label={`${actionText} with Apple`}
                                onClick={() => handleSocialAuth(appleSignIn)}
                                disabled={isProcessing}
                            />
                        )}
                    </SocialButtons>
                    <Separator>
                        <Line />
                        OR
                        <Line />
                    </Separator>
                </>
            )}
            <PhoneAndEmailButtons>
                <ToggleButton
                    shortLabel={`${actionText} via phone`}
                    longLabel={`${actionText} via phone`}
                    active={emailPhoneToggle === PHONE_TOGGLE}
                    onClick={() => {
                        setEmailPhoneToggle(PHONE_TOGGLE)
                        clearExistingValues()
                    }}
                />
                <ToggleButton
                    shortLabel={`${actionText} via email`}
                    longLabel={`${actionText} via email`}
                    active={emailPhoneToggle === EMAIL_TOGGLE}
                    onClick={() => {
                        setEmailPhoneToggle(EMAIL_TOGGLE)
                        clearExistingValues()
                    }}
                />
            </PhoneAndEmailButtons>
            {emailPhoneToggle === EMAIL_TOGGLE ? (
                <EmailPassword />
            ) : (
                <PhoneNumber />
            )}
            {/*if user is in new login flow, show option to sign up and vice versa*/}
            <StyledH6>
                {isNewLogin
                    ? 'Already have an account?'
                    : "Don't have an account?"}
                <button
                    onClick={() => {
                        setLoginType(isNewLogin ? EXISTING_LOGIN : NEW_LOGIN)
                        clearExistingValues()
                    }}
                    disabled={isProcessing}
                >
                    {isNewLogin ? 'Log In' : 'Sign Up'}
                </button>
            </StyledH6>
            {showCorporateLogin && <CorporateLogin />}
            <Disclosure />
            {isProcessing && (
                <div style={{ textAlign: 'center', margin: '10px 0' }}>
                    Processing your request...
                </div>
            )}
        </LoginFormBaseLayout>
    )
}

export default LoginForm
